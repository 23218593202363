<template>
  <v-app-bar
    app
    dense
  >
    <v-app-bar-nav-icon @click.stop="toggleNavbar">
      <v-icon>{{ toggleNavbarIcon }}</v-icon>
    </v-app-bar-nav-icon>
    <breadcrumbs />
    <v-spacer />
    <!-- <error-log /> -->
    <!-- <full-screen /> -->
    <!-- <notification /> -->
    <Calculator />
    <support />
    <personalmenu />
    <localization />
    <profile />
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex';
import Breadcrumbs from '../widget/AppBreadcrumbs.vue';
// import ErrorLog from '../widget/AppErrorLog.vue';
// import FullScreen from '../widget/AppFullScreen.vue';
import Localization from '../widget/AppLocalization.vue';
// import Notification from '../widget/AppNotification.vue';
import Profile from '../widget/AppProfile.vue';
import Personalmenu from '../widget/PersonalMenuDropdown.vue';
import Support from '../widget/Support.vue';
import Calculator from '../widget/Calculator.vue';

export default {
  name: 'TheLayoutToolbar',
  components: {
    Breadcrumbs,
    // ErrorLog,
    // FullScreen,
    Localization,
    // Notification,
    Profile,
    Personalmenu,
    Support,
    Calculator
  },
  data: () => ({}),
  computed: {
    ...mapGetters([
      'toolbarDense',
      'navbarShow',
      'user'
    ]),
    toggleNavbarIcon() {
      return this.navbarShow ? 'mdi-format-indent-decrease' : 'mdi-format-indent-increase';
    },
  },
  methods: {
    toggleNavbar() {
      this.$store.dispatch('NavbarToggle');
    },
  },
};
</script>
