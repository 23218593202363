<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="mx-2 rounded-circle" color="primary" width="35px" height="35px" v-bind="attrs" v-on="on" x-small :disabled="!menuList.length">
        <v-icon> mdi-menu-down-outline </v-icon>
      </v-btn>
    </template>
    <v-list dense link style="max-height: 500px" class="overflow-y-auto">
      <v-list-item :to="item.to" v-for="(item, index) in menuList" :key="index">
        <v-list-item-title>{{ $t(`route.${item.slug}`) }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PersonalMenuDropdown",
  computed: {
    ...mapGetters({
      menuList: "menuList",
    }),
  },
};
</script>
