<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" large class="mx-2" v-on="on" btn color="primary" @click="calculator">mdi-calculator</v-icon>
    </template>
  </v-menu>
</template>

<script>
export default {
  name: "Calculator",
  methods: {
    calculator() {
      window.open("https://www.google.com/search?q=calculator&oq=&aqs=chrome.1.69i57j69i59j0i131i433i512j0i512j0i433i512j69i60l2j69i61.1559j0j7&sourceid=chrome&ie=UTF-8")
    }
  }
};
</script>
