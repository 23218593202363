<template>
  <v-navigation-drawer
    color="accent lighten-1"
    fixed
    app
    :value="navbarShow"
    width="250"
    :right="locale === 'he' ? true : false"
    @input="stateNavbarShow"
  >
    <v-app-bar
      dense
      elevation="0"
      class="mb-3"
    >
      <v-toolbar-title class="text-center py-2">
        <v-img
          src="@/assets/logo.png"
          alt="VVA"
          class="mt-2"
          max-height="32"
          contain
        />
        <p class="text-caption pa-0 ma-0 primary--text">{{ user.email }}</p>
      </v-toolbar-title>
    </v-app-bar>
    <the-layout-drawer-list
      dense
      :routes="routes"
      icon-show
    />
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';
import TheLayoutDrawerList from './TheLayoutDrawerList.vue';
// import { routes } from '@/router';

export default {
  name: 'TheLayoutDrawer',
  components: {
    TheLayoutDrawerList,
  },
  data: () => ({
    routes: []
  }),
  created() {
    this.$router.options.routes.forEach(route => {
      this.routes.push(route)
    })
  },
  computed: {
    ...mapGetters([
      'navbarShow',
      'locale',
      'user'
    ]),
  },
  methods: {
    stateNavbarShow(state) {
      this.$store.dispatch('NavbarState', { state });
    },
  },
};
</script>
