<template>
  <v-list
    dense
    class="layout-drawer pe-1"
  >
    <div
      v-for="item in routes.filter(item => !item.hidden)"
      :key="item.title"
    >
      <v-list-item
        :ripple="false"
        active-class="activeClass"
        v-if="isVisibleItem(item)"
        :to="{path: resolvePath(onlyOneChild.path), query: item.meta.debitQuery ? debitReportQuery : ''}"
      >
        <v-list-item-icon class="layout-drawer__icon me-2">
          <v-icon class="text-h6" :class="'ps-'+item.meta.padding">{{ getListIcon(onlyOneChild) }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="text-body-2 mb-1">
            {{ getListTitle(onlyOneChild) }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-group
        v-else
        class="mt-2"
        active-class="activeClassGroup"
      >
        <template v-slot:activator>
          <v-list-item-icon class="layout-drawer__icon me-2">
            <v-icon class="text-h6" :class="'ps-'+item.meta.padding">{{getListIcon(item)}}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-body-2 mb-1">
              {{ getListTitle(item) }}
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <the-layout-drawer-list
          dense
          :routes="item.children"
          :base-path="resolvePath(item.path)"
          icon-show
        />
      </v-list-group>
    </div>
  </v-list>
</template>

<script>
import { mapGetters } from 'vuex';
import { resolve } from 'path';

export default {
  name: 'TheLayoutDrawerList',
  props: {
    iconShow: Boolean,
    routes: {
      type: Array,
      default: () => {},
    },
    basePath: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      debitReportQuery: 'debitReport/debitReportQuery'
    }),
  },
  data() {
    this.onlyOneChild = null;
    return {};
  },
  methods: {
    isVisibleItem(item) {
      return this.hasOneVisibleChild(item.children, item)
        && (!this.onlyOneChild.children || this.onlyOneChild.noVisibleChildren)
        && !item.alwaysShow;
    },
    hasOneVisibleChild(children = [], parent) {
      const visibleChildren = children.filter((item) => {
        if (item.hidden) return false;
        // Temp set(will be used if only has one visible child)
        this.onlyOneChild = item;
        return true;
      });

      // When there is only one child router, the child router is displayed by default
      if (visibleChildren.length === 1) {
        this.onlyOneChild.path = resolve(parent.path, this.onlyOneChild.path);
        this.onlyOneChild.meta.icon = this.onlyOneChild.meta.icon || parent.meta.icon || '';

        return true;
      }

      // Show parent if there are no child router to display
      if (visibleChildren.length === 0) {
        this.onlyOneChild = { ...parent, noVisibleChildren: true };
        return true;
      }

      return false;
    },
    resolvePath(path) {
      if (this.isExternal(path)) {
        return path;
      }
      return resolve(this.basePath, path);
    },
    getListIcon(item) {
      return this.iconShow && item.meta ? item.meta.icon : ' ';
    },
    getListTitle(item) {
      return item.meta ? this.$t(item.meta.title) : '';
    },
    isExternal(path) {
      return /^(https?:|mailto:|tel:)/.test(path);
    },
  },
};

</script>

<style lang="scss" scoped>
  .layout-drawer ::v-deep {
    padding-bottom: 0px;
    padding-top: 0px;
    .layout-drawer__icon {
      margin-bottom: 8px;
      margin-top: 8px;
    }
    .activeClass {
      /* background-color: var(--v-primary-base) !important; */
      background: linear-gradient(98deg,#000000,var(--v-primary-base) 94%);
      color: var(--v-primary-lighten1) !important;
      border-bottom-right-radius: 32px !important;
      border-top-right-radius: 32px !important;
      /* padding-left: 22px; */
    }
    .v-list-group__header__append-icon {
      margin: 0 !important;
    }
    .v-list-group__header__append-icon .mdi {
      color: var(--v-accent-darken1) !important;
    }
    .v-list-item:not(.activeClass):not(.v-list-item--disabled){
      color: var(--v-primary-darken1) !important;
    }
    .v-list-group--active {
      background-color: var(--v-accent-lighten3) !important;
      .v-list-item--active {
        background-color: var(--v-accent-lighten3) !important;
      }
    }
    .v-list-item, .v-list-group--active, .v-list-item:hover::before {
      border-bottom-right-radius: 32px !important;
      border-top-right-radius: 32px !important;
    }
    .v-list-item {
      height: 44px;
    }
  }
</style>
