<template>
  <v-breadcrumbs
    class="hidden-sm-and-down"
    :items="levelList"
    divider="/"
  >
    <template v-slot:item="props">
      <router-link class="text-decoration-none" :class="!props.item.disabled ? 'primary--text' : 'primary--text text--darken-1'" :to="!props.item.disabled ? {name: handleLink(props.item)} : ''">
        {{ generateTitle(props.item.meta.title) }}
      </router-link>
    </template>
  </v-breadcrumbs>
</template>

<script>
const pathToRegexp = require('path');

export default {
  name: 'AppBreadcrumbs',
  data: () => ({
    levelList: null,
  }),
  watch: {
    $route() {
      this.getBreadcrumb();
    },
  },
  created() {
    this.getBreadcrumb();
  },
  methods: {
    generateTitle(title) {
      const hasKey = this.$te(`${title}`);
      if (hasKey) {
        const translatedTitle = this.$t(`${title}`);
        return translatedTitle;
      }
      return title;
    },
    getBreadcrumb() {
      let matched = this.$route.matched.filter((item) => item.name);

      matched[matched.length - 1] = Object.assign({...matched[matched.length - 1], disabled: true});
      const first = matched[0];
      if (first && first.name.trim().toLocaleLowerCase() !== 'dashboard') {
        matched = [{ path: '/dashboard', name: 'Dashboard', meta: { title: 'route.dashboard' } }].concat(matched);
      }

      this.levelList = matched.filter((item) => item.meta
        && item.meta.title && item.meta.breadcrumb !== false);      
    },
    pathCompile(path) {
      const { params } = this.$route;
      const toPath = pathToRegexp.compile(path);
      return toPath(params);
    },
    handleLink(item) {
      const { name } = item;
      return name;
    },
  },
};
</script>
