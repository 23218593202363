<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" large class="mx-2" v-on="on" btn color="primary" @click="support">mdi-face-agent</v-icon>
    </template>
  </v-menu>
</template>

<script>
export default {
  name: "Support",
  methods: {
    support() {
      window.open("https://support.binaw.com/")
    },
  },
};
</script>
